import * as React from 'react';
import { PureComponent } from 'react';
import classnames from 'classnames';

import { toSnakeCase } from '../helpers/helpers';
import { uaParser } from '../ua-parser/ua-parser';

export enum UIType {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Tablet = 'tablet',
}

function getUIType() {
  if (uaParser.isDesktop()) {
    return UIType.Desktop;
  }

  return uaParser.isMobile()
    ? UIType.Mobile
    : UIType.Tablet;
}

export class DocumentHTML extends PureComponent {
  private readonly _uaParserResult = uaParser.getResult();

  private readonly _uaParserBrowser = uaParser.getBrowser();

  componentDidMount() {
    const html = document.documentElement;
    html.setAttribute('lang', 'en');
    html.className = this.stateAsClasses;
  }

  get stateAsClasses(): string {
    const { os } = this._uaParserResult;
    const { name, version } = this._uaParserBrowser;
    const { vendor, model } = uaParser.getDevice();
    // firstly setting device name from our liveUAParserJS. If no matches are found with us, then take with UAParserJS
    const deviceNameClass = uaParser.deviceName || toSnakeCase(model);
    const uiType = getUIType();
    // TODO:separate-classes-service
    // SASS mixins have tight dependency on '.root__' string for root classes
    // so all our root classes should be named as 'root__<anything>'
    return classnames(
      {
        [`root__device-vendor_${toSnakeCase(vendor)}`]: !!uaParser.getDevice().vendor,
        [`root__device-name_${deviceNameClass}`]: !!deviceNameClass,
      },
      `root__device-type_${uiType}`,
      `root__browser-name_${toSnakeCase(name)}`,
      `root__browser-version_${toSnakeCase(version)}`,
      `root__os-name_${toSnakeCase(os.name)}`,
      `root__os-version_${toSnakeCase(os.version)}`,
    ).toLowerCase();
  }

  render() {
    return <></>;
  }
}
