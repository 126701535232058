import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import expandCollapseIconRef from './icons/expand-collapse.svg';

import styles from './expertise-accordion.module.scss';

/* eslint-disable */
// @ts-ignore
function ExpertiseAccordion({ t }) {
  return (
    <div className={classNames(styles.ea, 'container')}>
      <Accordion className={styles.eaRootAccordion} preExpanded={['a']}>
        <AccordionItem className={styles.eaRootAccordionItem} uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton itemRef={expandCollapseIconRef} className={styles.eaRootAccordionItemButton}>
              {t('Mobile Development')}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={styles.eaRootAccordionItemTitle}>iOS</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Swift,Networking (WebSocket+ Swift Codable),  UIKit, MVC/MVP, Tuist,
              XcodeGen, Reactive Programming (MVVM + RxSwift/Combine), PinLayout
            </div>
            <div className={styles.eaRootAccordionItemTitle}>Android</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Kotlin. Android Jetpack (Arch components, Navigation, Room), DI: dagger,
              Kotlin Coroutines and Coroutine Flow, MVVM or MVI
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Multi-platform')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Flutter, Dart
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Architectural Approaches')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Tiered, Service Oriented, Microservice
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={styles.eaRootAccordionItem}>
          <AccordionItemHeading>
            <AccordionItemButton itemRef={expandCollapseIconRef} className={styles.eaRootAccordionItemButton}>
              Java {t('Development')}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={styles.eaRootAccordionItemTitle}>{t('Languages&Frameworks')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Java 11/17, Spring(boot, web, aop, data-jpa, cloud-sleuth, cloud-oauth2, amqp, test)
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Data Management')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Rdbms: PostgreSql, Oracle, Sql Server
              <br />
              nosql: MongoDb, Redis
              <br />
              Orm: Hibernate, MyBatis
              <br />
              KeyStore: Vault
              <br />
              Brokers: RabbitMq, Kafka
            </div>
            <div className={styles.eaRootAccordionItemTitle}>CI/CD</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Jenkins, Git, Rundeck
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Unit testing')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              NUnit, xUnit
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Cloud Services')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Azure
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Architectural Approaches')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Tiered, Service Oriented, Microservice
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Monitoring, logs')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Kibana, Grafana, Logstash, Filebeat, Prometheus, ELK
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={styles.eaRootAccordionItem}>
          <AccordionItemHeading>
            <AccordionItemButton itemRef={expandCollapseIconRef} className={styles.eaRootAccordionItemButton}>
              Front Web Development
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={styles.eaRootAccordionItemTitle}>{t('Languages&Frameworks')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Angular Framework SSR (server side rendering) / CSR (client side rendering),
              <br />
              ReactJs, NextJs (CSR, SSR, SSG, ISR), VueJs, TypeScript / JavaScript
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Data Management')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              MongoDB
            </div>
            <div className={styles.eaRootAccordionItemTitle}>CI/CD</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Jenkins, Git, Rundeck
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Package Managers')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Npm (Private Repository), Npx, Yarn
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Architectural Approaches')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Frontend Microservices (Micro Frontend Modules), NX, Nrwl
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Unit testing')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Karma, Jasmine, Jest, Cypress
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={styles.eaRootAccordionItem}>
          <AccordionItemHeading>
            <AccordionItemButton itemRef={expandCollapseIconRef} className={styles.eaRootAccordionItemButton}>
              .NET {t('Development')}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={styles.eaRootAccordionItemTitle}>{t('Languages&Frameworks')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              .Net Framework, .Net Core
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Data Management')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              RDBMS - SQL Server, PostgreSQL; *
              <br />
              NoSQL - Redis, MongoDB; *
              <br />
              ORM - EF, EF Core, Dapper
              <br />
              KeyStore – Vault
              <br />
              Brokers – Kafka, RabbitMQ
            </div>
            <div className={styles.eaRootAccordionItemTitle}>CI/CD</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Jenkins, Git, Rundeck
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Unit testing')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              NUnit, xUnit
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Cloud Services')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Azure
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Architectural Approaches')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Tiered, Service Oriented, Microservice
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Monitoring, logs')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Kibana, Grafana, Logstash, Filebeat, Prometheus, ELK
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={styles.eaRootAccordionItem}>
          <AccordionItemHeading>
            <AccordionItemButton itemRef={expandCollapseIconRef} className={styles.eaRootAccordionItemButton}>
              Creatio {t('Development')}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={styles.eaRootAccordionItemTitle}>{t('Languages&Frameworks')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              .Net Framework, .Net Core, Ext.JS, Require.JS
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Data Management')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              RDBMS - SQL Server
              <br />
              NoSQL - Redis
              <br />
              ORM - EF, EF Core, ODATA
              <br />
              Brokers – RabbitMQ
            </div>
            <div className={styles.eaRootAccordionItemTitle}>CI/CD</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Jenkins, Git
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Monitoring, logs')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Prometeus, Kibana, Grafana, log4net
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Auto testing / Manual Testing')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              .NetCore, .NUnit, Atata, RestClient, Jmeter, Postman, Swagger, Apiary
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Unit testing')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Moq, NUnit, xUnit
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Architectural Approaches')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              SOLID, OOD, Design patterns
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Cloud Services')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              AWS, Azure, IBM
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={styles.eaRootAccordionItem}>
          <AccordionItemHeading>
            <AccordionItemButton itemRef={expandCollapseIconRef} className={styles.eaRootAccordionItemButton}>
              {t('Infrastructure Development')}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={styles.eaRootAccordionItemTitle}>{t('Languages&Frameworks')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              .Net Framework, .Net Core, Ext.JS, Require.JS
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Data Management')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              RDBMS - SQL Server
              <br />
              NoSQL - Redis
              <br />
              ORM - EF, EF Core, ODATA
              <br />
              Brokers – RabbitMQ
            </div>
            <div className={styles.eaRootAccordionItemTitle}>CI/CD</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Jenkins, Git
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Monitoring, logs')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Prometeus, Kibana, Grafana, log4net
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Auto testing / Manual Testing')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              .NetCore, .NUnit, Atata, RestClient, Jmeter, Postman, Swagger, Apiary
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Unit testing')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Moq, NUnit, xUnit
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Architectural Approaches')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              SOLID, OOD, Design patterns
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Cloud Services')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              AWS, Azure, IBM
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={styles.eaRootAccordionItem}>
          <AccordionItemHeading>
            <AccordionItemButton itemRef={expandCollapseIconRef} className={styles.eaRootAccordionItemButton}>
              {t('Data Development & Data Science')}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <div className={styles.eaRootAccordionItemTitle}>{t('Data Integration')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              CDC, AIRFlow, IBM Data Stage
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Data Warehouse')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              DBMS – SAP IQ
              <br />
              Online & offline integration with 100+ DBMS (MS SQL, Oracle, PostgreSQL, MongoDB...)
              <br />
              Create business data model (funds transfer pricing, FTP & other)
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Reporting')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              SSRS & Power BI
              <br />
              Cloud & on-premise
              <br />
              Web & mobile
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Data Quality')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Audit & Consulting, Data Lineage & Controlling
            </div>
            <div className={styles.eaRootAccordionItemTitle}>CI/CD</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Jenkins, Git
            </div>
            <div className={styles.eaRootAccordionItemTitle}>{t('Data Science')}</div>
            <div className={styles.eaRootAccordionItemDescription}>
              Linear/Logistic  Regression, Decision Trees, Random Forest,
              XGBoost/LightGBM, Neural Networks
              <br />
              From Finance Fraud Detection Models To RecommendationModels
              <br />
              AutoML soft
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default withTranslation()(ExpertiseAccordion)
