import React from 'react';
import { withTranslation } from 'react-i18next';

import styles from './expertise-and-technology.module.scss';

/* eslint-disable */
// @ts-ignore
function ExpertiseAndTechnology({ t }) {
  return (
    <div className={styles.et} id="Technologies">
      <div className={styles.etTitle}>
        {t('Expertise')}
        <br />
        {t('and Technology')}
      </div>

      <div className={styles.etAnimatedWrapper}>
        <div className={`${styles.etAnimatedLeft} etAnimatedLeftFirst`}>{t('Languages and Frameworks')}</div>
        <div className={styles.etAnimatedRight}>
          Java, NodeJS, Angular, Spring, .Net Framework, .Net Core, JavaScript, TypeScript, C#, Python, ESQL, OpenApi, Kotlin, Swift, Flutter
        </div>
      </div>
      <div className={styles.etAnimatedWrapper}>
        <div className={`${styles.etAnimatedLeft} etAnimatedLeftSecond`}>{t('Infrastructure')}</div>
        <div className={styles.etAnimatedRight}>
          Kubernetes, Docker, Nginx, Wildfly, IBM WAS7/WAS9, IBM ESB/IIB,
          DataStage, Orchestrator, Tomcat, Traefic, Jaeger
        </div>
      </div>
      <div className={styles.etAnimatedWrapper}>
        <div className={`${styles.etAnimatedLeft} etAnimatedLeftThird`}>{t('Data Management')}</div>
        <div className={styles.etAnimatedRight}>
          PostgreSQL, Mongo, IBM DB2, SybaseIQ, MS SQL, ElasticSearch, Redis, RabbitMQ, Vault, Prometheus,
          Consul, Apache Airflow, Oracle
        </div>
      </div>
      <div className={styles.etAnimatedWrapper}>
        <div className={`${styles.etAnimatedLeft} etAnimatedLeftFourth`}>{t('Additional Software')}</div>
        <div className={styles.etAnimatedRight}>
          Apiman, Keycloak, Kibana, Grafana, Logstash, Filebeat, Ansible, Gitlab, Jenkins, Rundeck,
          Apach JMeter, Selenium, Testcafe, RestAssured
        </div>
      </div>
      <div className={styles.etAnimatedWrapper}>
        <div className={`${styles.etAnimatedLeft} etAnimatedLeftFifth`}>BPM</div>
        <div className={styles.etAnimatedRight}>
          Corezoid, Creatio
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ExpertiseAndTechnology)
