import React from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';

import styles from './expertise-tabs.module.scss';

export default function ExpertiseTabs() {
  return (
    <div className={styles.et}>
      <Tabs className={styles.etTabWrapper}>

        <TabList className={styles.etTabList}>
          <Tab className={styles.etTab}>
            <p>Mobile Development</p>
          </Tab>
          <Tab className={styles.etTab}>
            <p>Front Web Development</p>
          </Tab>
          <Tab className={styles.etTab}>
            <p>Java Development</p>
          </Tab>
          <Tab className={styles.etTab}>
            <p>.NET Development</p>
          </Tab>
          <Tab className={styles.etTab}>
            <p>Creatio Development</p>
          </Tab>
          <Tab className={styles.etTab}>
            <p>Infrastructure Development</p>
          </Tab>
          <Tab className={styles.etTab}>
            <p>Data Development & Data Science</p>
          </Tab>
        </TabList>

        <TabPanel className={styles.etTabPanel}>

          <div className={styles.etTabPanelCol}>
            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>iOS</div>
              <div className={styles.etTabPanelItemContent}>
                Swift,Networking (WebSocket+ Swift Codable), UIKit, MVC/MVP, Tuist, XcodeGen,
                Reactive Programming (MVVM + RxSwift/Combine), PinLayout
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Multi-platform</div>
              <div className={styles.etTabPanelItemContent}>Flutter, Dart</div>
            </div>
          </div>

          <div className={styles.etTabPanelCol}>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Android</div>
              <div className={styles.etTabPanelItemContent}>
                Kotlin. Android Jetpack (Arch components, Navigation, Room), DI: dagger,
                Kotlin Coroutines and Coroutine Flow, MVVM or MVI
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Architectural Approaches</div>
              <div className={styles.etTabPanelItemContent}>Tiered, Service Oriented, Microservice</div>
            </div>
          </div>

        </TabPanel>

        <TabPanel className={styles.etTabPanel}>

          <div className={styles.etTabPanelCol}>
            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Languages&Frameworks</div>
              <div className={styles.etTabPanelItemContent}>
                Angular Framework SSR (server side rendering) / CSR (client side rendering),
                ReactJs, NextJs (CSR, SSR, SSG, ISR), VueJs, TypeScript / JavaScript
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Data Management</div>
              <div className={styles.etTabPanelItemContent}>MongoDB</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>CI/CD</div>
              <div className={styles.etTabPanelItemContent}>
                Jenkins, Git, Rundeck
              </div>
            </div>
          </div>

          <div className={styles.etTabPanelCol}>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Package Managers</div>
              <div className={styles.etTabPanelItemContent}>
                Npm (Private Repository), Npx, Yarn
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Architectural Approaches</div>
              <div className={styles.etTabPanelItemContent}>
                Frontend Microservices (Micro Frontend Modules), NX, Nrwl
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Unit testing</div>
              <div className={styles.etTabPanelItemContent}>
                Karma, Jasmine, Jest, Cypress
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel className={styles.etTabPanel}>

          <div className={styles.etTabPanelCol}>
            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Languages&Frameworks</div>
              <div className={styles.etTabPanelItemContent}>
                Java 11/17, Spring(boot, web, aop, data-jpa, cloud-sleuth, cloud-oauth2, amqp, test)
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Data Management</div>
              <div className={styles.etTabPanelItemContent}>
                Rdbms: PostgreSql, Oracle, Sql Server
                <br />
                nosql: MongoDb, Redis
                <br />
                Orm: Hibernate, MyBatis
                <br />
                KeyStore: Vault
                <br />
                Brokers: RabbitMq, Kafka
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>CI/CD</div>
              <div className={styles.etTabPanelItemContent}>Jenkins, Git, Rundeck</div>
            </div>
          </div>

          <div className={styles.etTabPanelCol}>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Unit testing</div>
              <div className={styles.etTabPanelItemContent}>Junit, Mockito</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Cloud Services</div>
              <div className={styles.etTabPanelItemContent}>AWS, Azure, IBM</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Architectural Approaches</div>
              <div className={styles.etTabPanelItemContent}>SOLID, OOD, Design Patterns</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Monitoring, logs</div>
              <div className={styles.etTabPanelItemContent}>Kibana, Grafana, Logstash, Filebeat, Prometheus, ELK</div>
            </div>
          </div>

        </TabPanel>

        <TabPanel className={styles.etTabPanel}>

          <div className={styles.etTabPanelCol}>
            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Languages&Frameworks</div>
              <div className={styles.etTabPanelItemContent}>.Net Framework, .Net Core</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Data Management</div>
              <div className={styles.etTabPanelItemContent}>
                RDBMS - SQL Server, PostgreSQL; *
                <br />
                NoSQL - Redis, MongoDB; *
                <br />
                ORM - EF, EF Core, Dapper
                <br />
                KeyStore – Vault
                <br />
                Brokers – Kafka, RabbitMQ
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>CI/CD</div>
              <div className={styles.etTabPanelItemContent}>Jenkins, Git, Rundeck</div>
            </div>
          </div>

          <div className={styles.etTabPanelCol}>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Unit testing</div>
              <div className={styles.etTabPanelItemContent}>NUnit, xUnit</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Cloud Services</div>
              <div className={styles.etTabPanelItemContent}>Azure</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Architectural Approaches</div>
              <div className={styles.etTabPanelItemContent}>Tiered, Service Oriented, Microservice</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Monitoring, logs</div>
              <div className={styles.etTabPanelItemContent}>Kibana, Grafana, Logstash, Filebeat, Prometheus, ELK</div>
            </div>
          </div>

        </TabPanel>

        <TabPanel className={styles.etTabPanel}>

          <div className={styles.etTabPanelCol}>
            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Languages&Frameworks</div>
              <div className={styles.etTabPanelItemContent}>.Net Framework, .Net Core, Ext.JS, jQuery, Angular</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Data Management</div>
              <div className={styles.etTabPanelItemContent}>
                RDBMS - SQL Server
                <br />
                NoSQL - Redis
                <br />
                Brokers – RabbitMQ
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>CI/CD</div>
              <div className={styles.etTabPanelItemContent}>Jenkins, Git, GitLab, SVN, TeamCity</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Monitoring, logs</div>
              <div className={styles.etTabPanelItemContent}>Prometeus, Kibana, Grafana, log4net</div>
            </div>
          </div>

          <div className={styles.etTabPanelCol}>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Auto testing / Manual Testing</div>
              <div className={styles.etTabPanelItemContent}>
                Atata, Jmeter, Postman, Apiary
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Unit testing</div>
              <div className={styles.etTabPanelItemContent}>Moq, NUnit, xUnit</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Architectural Approaches</div>
              <div className={styles.etTabPanelItemContent}>SOLID, OOD, Design patterns</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Cloud Services</div>
              <div className={styles.etTabPanelItemContent}>AWS, Azure</div>
            </div>
          </div>

        </TabPanel>

        <TabPanel className={styles.etTabPanel}>

          <div className={styles.etTabPanelCol}>
            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Languages&Frameworks</div>
              <div className={styles.etTabPanelItemContent}>
                .Net Framework, .Net Core, Ext.JS, Require.JS
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Data Management</div>
              <div className={styles.etTabPanelItemContent}>
                RDBMS - SQL Server
                <br />
                NoSQL - Redis
                <br />
                ORM - EF, EF Core, ODATA
                <br />
                Brokers – RabbitMQ
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>CI/CD</div>
              <div className={styles.etTabPanelItemContent}>Jenkins, Git</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Monitoring, logs</div>
              <div className={styles.etTabPanelItemContent}>Prometeus, Kibana, Grafana, log4net</div>
            </div>
          </div>

          <div className={styles.etTabPanelCol}>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Auto testing / Manual Testing</div>
              <div className={styles.etTabPanelItemContent}>
                .NetCore, .NUnit, Atata, RestClient, Jmeter, Postman, Swagger, Apiary
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Unit testing</div>
              <div className={styles.etTabPanelItemContent}>Moq, NUnit, xUnit</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Architectural Approaches</div>
              <div className={styles.etTabPanelItemContent}>SOLID, OOD, Design patterns</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Cloud Services</div>
              <div className={styles.etTabPanelItemContent}>AWS, Azure, IBM</div>
            </div>
          </div>

        </TabPanel>

        <TabPanel className={styles.etTabPanel}>

          <div className={styles.etTabPanelCol}>
            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Data Integration</div>
              <div className={styles.etTabPanelItemContent}>CDC, AIRFlow, IBM Data Stage</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Data Warehouse</div>
              <div className={styles.etTabPanelItemContent}>
                DBMS – SAP IQ
                <br />
                Online & offline integration with 100+ DBMS (MS SQL, Oracle, PostgreSQL, MongoDB...)
                <br />
                Create business data model (funds transfer pricing, FTP & other)
              </div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Reporting</div>
              <div className={styles.etTabPanelItemContent}>
                SSRS & Power BI
                <br />
                Cloud & on-premise
                <br />
                Web & mobile
              </div>
            </div>
          </div>

          <div className={styles.etTabPanelCol}>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Data Quality</div>
              <div className={styles.etTabPanelItemContent}>Audit & Consulting, Data Lineage & Controlling</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>CI/CD</div>
              <div className={styles.etTabPanelItemContent}>Jenkins, Git</div>
            </div>

            <div className={styles.etTabPanelItem}>
              <div className={styles.etTabPanelItemTitle}>Data Science</div>
              <div className={styles.etTabPanelItemContent}>
                Linear/Logistic Regression, Decision Trees, Random Forest, XGBoost/LightGBM, Neural Networks
                <br />
                From Finance Fraud Detection Models To RecommendationModels
                <br />
                AutoML soft
              </div>
            </div>
          </div>

        </TabPanel>

      </Tabs>
    </div>
  );
}
