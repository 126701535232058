/* eslint-disable */
export const enum SupportedDeviceName {
  SAMSUNG_GALAXY_NOTE_8 = 'SAMSUNG_GALAXY_NOTE_8',
  SAMSUNG_GALAXY_NOTE_5 = 'SAMSUNG_GALAXY_NOTE_5',
  SAMSUNG_GALAXY_NOTE_EDGE = 'SAMSUNG_GALAXY_NOTE_EDGE',
  SAMSUNG_GALAXY_TAB_4_10_1 = 'SAMSUNG_GALAXY_TAB_4_10_1',
  SAMSUNG_GALAXY_TAB_3_10_1 = 'SAMSUNG_GALAXY_TAB_3_10_1',
  SAMSUNG_GALAXY_S8 = 'SAMSUNG_GALAXY_S8',
  SAMSUNG_GALAXY_S8_PLUS = 'SAMSUNG_GALAXY_S8_PLUS',
  SAMSUNG_GALAXY_S9 = 'SAMSUNG_GALAXY_S9',
  SAMSUNG_GALAXY_S9_PLUS = 'SAMSUNG_GALAXY_S9_PLUS',
  SAMSUNG_GALAXY_S7 = 'SAMSUNG_GALAXY_S7',
  SAMSUNG_GALAXY_S4 = 'SAMSUNG_GALAXY_S4',
  LG_G4 = 'LG_G4',
  GOOGLE_PIXEL = 'GOOGLE_PIXEL',
  LENOVO_A850 = 'LENOVO_A850',
  LENOVO_A889 = 'LENOVO_A889',
  IPHONE = 'IPHONE',
  IPHONE_5_SE = 'IPHONE_5_SE',
  IPHONE_6 = 'IPHONE_6',
  IPHONE_6S = 'IPHONE_6S',
  IPHONE_7 = 'IPHONE_7',
  IPHONE_8 = 'IPHONE_8',
  IPHONE_6_7_8 = 'IPHONE_6_7_8',
  IPHONE_6_PLUS = 'IPHONE_6_PLUS',
  IPHONE_6S_PLUS = 'IPHONE_6S_PLUS',
  IPHONE_7_PLUS = 'IPHONE_7_PLUS',
  IPHONE_8_PLUS = 'IPHONE_8_PLUS',
  IPHONE_6_7_8_PLUS = 'IPHONE_6_7_8_PLUS',
  IPHONE_X = 'IPHONE_X',
  IPHONE_X_R = 'IPHONE_X_R',
  IPHONE_X_MAX = 'IPHONE_X_MAX',
}

export const enum ListNameGPU {
  APPLE_A8_GPU = 'Apple A8 GPU',
  APPLE_A9_GPU = 'Apple A9 GPU',
  APPLE_A10_GPU = 'Apple A10 GPU',
  APPLE_A11_GPU = 'Apple A11 GPU',
}

export const enum IphoneScreenHeight {
  IPHONE_6_7_8_PLUS = 736,
  IPHONE_6_7_8 = 667,
  IPHONE_X_XS = 812,
  IPHONE_XMAX_XR = 896,
  IPhone_5_SE = 568,
  /**
   * Browsers return wrong screen height for IPhone XR in case Zoomed mode is activated:
   * Settings > Display & Brightness > Display Mode = Zoomed.
   * Also browsers such as UC and Opera always return wrong height;
   */
  IPHONE_XR_ZOOMED_MODE = 812,
}

export enum IOSDeviceEnum {
  IPhone = 'iPhone',
  IPhone_5_SE = 'IPHONE_5_SE',
  IPhone_6_7_8 = 'IPHONE_6_7_8',
  Iphone_6_7_8_plus = 'IPHONE_6_7_8_PLUS',
  IPhone_X_XS = 'IPHONE_X',
  IPhone_XR = 'IPHONE_X_R',
  IPhone_XS_MAX = 'IPHONE_X_MAX',
}

export const DevicesRegExpMap: ReadonlyMap<SupportedDeviceName, RegExp> = new Map([
  [SupportedDeviceName.SAMSUNG_GALAXY_NOTE_8, /SM-N950/i],
  [SupportedDeviceName.SAMSUNG_GALAXY_NOTE_5, /N920/i],
  [SupportedDeviceName.SAMSUNG_GALAXY_NOTE_EDGE, /N915/i],
  [SupportedDeviceName.SAMSUNG_GALAXY_TAB_4_10_1, /SM-T53/i],
  [SupportedDeviceName.SAMSUNG_GALAXY_TAB_3_10_1, /GT-P52/i],
  [SupportedDeviceName.SAMSUNG_GALAXY_S8, /G950/i],
  [SupportedDeviceName.SAMSUNG_GALAXY_S8_PLUS, /G955/i],
  [SupportedDeviceName.SAMSUNG_GALAXY_S9, /SM-G960/i],
  [SupportedDeviceName.SAMSUNG_GALAXY_S9_PLUS, /SM-G965/i],
  [SupportedDeviceName.SAMSUNG_GALAXY_S7, /SM-G930/i],
  [SupportedDeviceName.SAMSUNG_GALAXY_S4, /(?:GT-I95|SHV-E300)/i],
  [SupportedDeviceName.LG_G4, /(?:LG.(?:H818|H815|H812|H810|H811|LS991|VS986|US991))/i],
  [SupportedDeviceName.GOOGLE_PIXEL, /Pixel Build/i],
  [SupportedDeviceName.LENOVO_A850, /Lenovo.A850/i],
  [SupportedDeviceName.LENOVO_A889, /Lenovo.A889/i],
  [SupportedDeviceName.IPHONE, /\WiPhone\W/i]
]);
/* eslint-enable */
