import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { withTranslation } from 'react-i18next';
import { uaParser } from '../../common/ua-parser/ua-parser';

import styles from './benefits.module.scss';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import grainSrcRef from './images/grain-image.png';
import slideItemIcon from './images/icon.png';
import xPatternRef from './images/pattern.png';

/* eslint-disable */
// @ts-ignore
function Benefits({ t }) {
  return (
    <>
      <div className={styles.benefits}>
        <div className={styles.benefitsWrapper}>

          <div className={styles.benefitsWrapperTitle}>

            <div className={styles.benefitsWrapperTitleImage}>
              <img src={grainSrcRef} alt="Ukrainian Grain" />
            </div>

            <div className={styles.benefitsWrapperTitleText}>
              {t('What are the benefits of collaborating with Ukrainian developers?')}
            </div>
          </div>

          { uaParser.isMobile() &&
            <div className={styles.benefitsWrapperSliderControls}>
              <button type="button" className={`${styles.benefitsWrapperSliderControl} trigger-prev`}>
              <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.64645 0.646447C5.84171 0.451184 6.15829 0.451184 6.35355 0.646447C6.54882 0.841709
                     6.54882 1.15829 6.35355 1.35355L5.64645 0.646447ZM1 6L0.646446 6.35355C0.451185 6.15829
                      0.451185 5.84171 0.646446 5.64645L1 6ZM6.35355 10.6464C6.54882 10.8417 6.54882 11.1583
                       6.35355 11.3536C6.15829 11.5488 5.84171 11.5488 5.64645 11.3536L6.35355 10.6464ZM6.35355
                        1.35355L1.35355 6.35355L0.646446 5.64645L5.64645 0.646447L6.35355 1.35355ZM1.35355
                         5.64645L6.35355 10.6464L5.64645 11.3536L0.646446 6.35355L1.35355 5.64645ZM1 5.5H18.5V6.5H1V5.5Z"
                    fill="#032436"
                  />
                </svg>
              </button>
              <button type="button" className={`${styles.benefitsWrapperSliderControl} trigger-next`}>
                <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.3536 0.646447C13.1583 0.451184 12.8417 0.451184 12.6464 0.646447C12.4512 0.841709
                     12.4512 1.15829 12.6464 1.35355L13.3536 0.646447ZM18 6L18.3536 6.35355C18.5488 6.15829
                      18.5488 5.84171 18.3536 5.64645L18 6ZM12.6464 10.6464C12.4512 10.8417 12.4512 11.1583
                       12.6464 11.3536C12.8417 11.5488 13.1583 11.5488 13.3536 11.3536L12.6464 10.6464ZM12.6464
                        1.35355L17.6464 6.35355L18.3536 5.64645L13.3536 0.646447L12.6464 1.35355ZM17.6464
                         5.64645L12.6464 10.6464L13.3536 11.3536L18.3536 6.35355L17.6464 5.64645ZM18 5.5H0.5V6.5H18V5.5Z"
                    fill="#032436"
                  />
                </svg>
              </button>
            </div>
          }

        </div>

        <div className={styles.benefitsWrapperSlider}>
          <Swiper
            navigation={{ nextEl: '.trigger-next', prevEl: '.trigger-prev' }}
            modules={[Navigation]}
            className={styles.benefitsSwiper}
            loop={true}
            spaceBetween={50}
            slidesPerView="auto"
          >
            <SwiperSlide className={styles.benefitsWrapperSliderSlide}>
              <div className={styles.benefitsWrapperSliderSlideIcon}>
                <img src={slideItemIcon} alt="Slide Icon" />
              </div>
              <div className={styles.benefitsWrapperSliderSlideTitle}>{t('Proficient tech specialists')}</div>
              <div className={styles.benefitsWrapperSliderSlideDescription}>
                {t('In Ukraine there are more than 200K proficient tech specialists with a degree in Science, Technology, Engineering&Mathematics')}
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.benefitsWrapperSliderSlide}>
              <div className={styles.benefitsWrapperSliderSlideIcon}>
                <img src={slideItemIcon} alt="Slide Icon" />
              </div>
              <div className={styles.benefitsWrapperSliderSlideTitle}>{t('Largest companies')}</div>
              <div className={styles.benefitsWrapperSliderSlideDescription}>
                {t('Over 100 "Fortune 500" companies are clients of Ukrainian IT industry')}
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.benefitsWrapperSliderSlide}>
              <div className={styles.benefitsWrapperSliderSlideIcon}>
                <img src={slideItemIcon} alt="Slide Icon" />
              </div>
              <div className={styles.benefitsWrapperSliderSlideTitle}>{t('$8.4B export volume 2025')}</div>
              <div className={styles.benefitsWrapperSliderSlideDescription}>
                {t('IT industry of Ukraine will reach according to PwC forecast')}
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.benefitsWrapperSliderSlide}>
              <div className={styles.benefitsWrapperSliderSlideIcon}>
                <img src={slideItemIcon} alt="Slide Icon" />
              </div>
              <div className={styles.benefitsWrapperSliderSlideTitle}>{t('More than $5B export volume 2020')}</div>
              <div className={styles.benefitsWrapperSliderSlideDescription}>
                {t('According to Better Regulation Delivery Office (BRDO)')}
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.benefitsWrapperSliderSlide}>
              <div className={styles.benefitsWrapperSliderSlideIcon}>
                <img src={slideItemIcon} alt="Slide Icon" />
              </div>
              <div className={styles.benefitsWrapperSliderSlideTitle}>{t('Average hourly rate')}</div>
              <div className={styles.benefitsWrapperSliderSlideDescription}>
                {t('of Ukrainian Software Engineer gives a better perspective on the quality and confidence in the developed software')}
              </div>
            </SwiperSlide>
            <SwiperSlide className={styles.benefitsWrapperSliderSlide}>
              <div className={styles.benefitsWrapperSliderSlideIcon}>
                <img src={slideItemIcon} alt="Slide Icon" />
              </div>
              <div className={styles.benefitsWrapperSliderSlideTitle}>{t('Diia.City')}</div>
              <div className={styles.benefitsWrapperSliderSlideDescription}>
                {t('Provides unique intellectual property protection guaranties for customers of IT business, low tax rates and simple administration process for Ukrainian IT-business')}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        { !uaParser.isMobile() &&
          <div className={styles.benefitsWrapperSliderControls}>
            <button type="button" className={`${styles.benefitsWrapperSliderControl} trigger-prev`}>
              <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.64645 0.646447C5.84171 0.451184 6.15829 0.451184 6.35355 0.646447C6.54882 0.841709
                 6.54882 1.15829 6.35355 1.35355L5.64645 0.646447ZM1 6L0.646446 6.35355C0.451185 6.15829
                  0.451185 5.84171 0.646446 5.64645L1 6ZM6.35355 10.6464C6.54882 10.8417 6.54882 11.1583
                   6.35355 11.3536C6.15829 11.5488 5.84171 11.5488 5.64645 11.3536L6.35355 10.6464ZM6.35355
                    1.35355L1.35355 6.35355L0.646446 5.64645L5.64645 0.646447L6.35355 1.35355ZM1.35355
                     5.64645L6.35355 10.6464L5.64645 11.3536L0.646446 6.35355L1.35355 5.64645ZM1 5.5H18.5V6.5H1V5.5Z"
                  fill="#032436"
                />
              </svg>
            </button>
            <button type="button" className={`${styles.benefitsWrapperSliderControl} trigger-next`}>
              <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.3536 0.646447C13.1583 0.451184 12.8417 0.451184 12.6464 0.646447C12.4512 0.841709
                 12.4512 1.15829 12.6464 1.35355L13.3536 0.646447ZM18 6L18.3536 6.35355C18.5488 6.15829
                  18.5488 5.84171 18.3536 5.64645L18 6ZM12.6464 10.6464C12.4512 10.8417 12.4512 11.1583
                   12.6464 11.3536C12.8417 11.5488 13.1583 11.5488 13.3536 11.3536L12.6464 10.6464ZM12.6464
                    1.35355L17.6464 6.35355L18.3536 5.64645L13.3536 0.646447L12.6464 1.35355ZM17.6464
                     5.64645L12.6464 10.6464L13.3536 11.3536L18.3536 6.35355L17.6464 5.64645ZM18 5.5H0.5V6.5H18V5.5Z"
                  fill="#032436"
                />
              </svg>
            </button>
          </div>
        }

      </div>
      <div className={styles.benefitsPatternHolder}>
        <img src={xPatternRef} alt="X Patern" />
      </div>
    </>
  );
}

export default withTranslation()(Benefits)
