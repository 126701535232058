import React from 'react';

// the hoc
import { withTranslation } from 'react-i18next';

import styles from './subheader.module.scss';

/* eslint-disable */
// @ts-ignore
function Subheader({ t }) {
  const headerHeight = 100;
  const smoothScrollTo = (el: string) => {
    const targetElRef = document.getElementById(el);
    const scrollConfigs = {
      top: targetElRef ? targetElRef?.offsetTop - headerHeight : 0,
      behavior: 'smooth',
    };
    window.scrollTo(scrollConfigs as ScrollToOptions);
  };
  const titleText = t('We just make cool digital fintech products');
  const descriptionText = t('value rather than technology');

  // @ts-ignore
  return (
      <div className={`${styles.subheader} container`} id='WhoWeAre'>
        <div className={styles.subheaderWrapper}>
          <div
            className={styles.subheaderTitle}
            dangerouslySetInnerHTML={{ __html: titleText }}
          />
          <div
            className={styles.subheaderDescription}
            dangerouslySetInnerHTML={{ __html: descriptionText }}
          />
          <button
            type='submit'
            onClick={() => smoothScrollTo('Contacts')}
            className={styles.subheaderContactUs}
          >
            {t('Contact us')}
          </button>
        </div>
      </div>
  );
}

export default withTranslation()(Subheader)
