import React from 'react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import styles from './our-partners.module.scss';

// Images
import corezoidImgSrc from './images/corezoid-64.png';
import grainImgSrc from './images/grain-pattern.svg';

// @ts-ignore
// @eslint-disable
function OurPartners(props: { isMobile: boolean }) {
  const goToPartnerWebsite = (url: string) => {
    window.open(url);
  };

  return (
    <div className={styles.ops} id="Partners">
      <div className={styles.opsTitle}>
        <>
          {t('Our partners')}
        </>
      </div>

      <div className={styles.opsCard}>

        <div className={styles.opsCardImage}>
          <img src={corezoidImgSrc} alt="Corezoid" />
        </div>

        <div className={styles.opsCardCtnt}>

          <div className={styles.opsCardCtntTitle}>Corezoid inc.</div>

          <ul>
            <li>
              <>
                {t('United ecosystem for managing all company processes')}
              </>
            </li>
            <li>
              <>
                {t('Rapid prototyping and making changes')}
              </>
            </li>
            <li>
              <>
                {t('Lower time-to-market down to 0')}
              </>
            </li>
            <li>
              <>
                {t('Hosting without hosting')}
              </>
            </li>
          </ul>

          <button
            type="submit"
            onClick={() => goToPartnerWebsite('https://corezoid.com/')}
            className={styles.opsCardCtntCtrlr}
          >
            <>
              {t('Go to Partner website')}
            </>
          </button>

        </div>

        <img
          src={grainImgSrc}
          style={{ display: props.isMobile ? 'none' : 'flex' }}
          alt="Grain"
          className={styles.opsCardGrain}
        />
      </div>
    </div>
  );
}

export default withTranslation()(OurPartners);
