import React from 'react';

import logo from './images/logo.svg';
import logoDescription from './images/logo-description.svg';

import styles from './logo.module.scss';

export default function Logo() {
  return (
    <div className={styles.logo}>
      <img src={logo} className={styles.logo__image} alt="Logo" />
      <div className={styles.logo__description}>
        <img
          src={logoDescription}
          className={styles.logo__description_image}
          alt="Logo Description"
        />
      </div>
    </div>
  );
}
