import { UAParser } from 'ua-parser-js';
import {
  DevicesRegExpMap,
  IOSDeviceEnum,
  IphoneScreenHeight,
  ListNameGPU,
  SupportedDeviceName,
} from './types';

/* eslint-disable */
function includesString(src: string | undefined, part: string, caseInsensitive: boolean = true): boolean {
  if (!src) {
    return false;
  }

  const [_src, _part] = [src, part].map(_ => caseInsensitive ? _.toLowerCase() : _);
  return _src.includes(_part);
}

export class UaParser {
  readonly deviceName?: SupportedDeviceName;
  private readonly _browser: UAParser.IBrowser;
  private readonly _device: UAParser.IDevice;
  private readonly _os: UAParser.IOS;
  private _isOperaMini: boolean | undefined;
  private readonly _uaParser = new UAParser();

  constructor() {
    this.deviceName = this.getDeviceName();
    this._browser = this.getBrowser();
    this._device = this.getDevice();
    this._os = this.getOS();
  }

  isMobile(): boolean {
    return this._device.type === UAParser.DEVICE.MOBILE;
  }

  isTablet(): boolean {
    return this._device.type === UAParser.DEVICE.TABLET;
  }

  isDesktop(): boolean {
    return !this.isMobile() && !this.isTablet();
  }

  isAndroid(): boolean {
    return this._os.name === 'Android';
  }

  isIOS(): boolean {
    return this._os.name === 'iOS';
  }

  isIos13AndHigher(): boolean {
    return this.isIOS() && parseInt(this.getBrowser().version!, 10) >= 13;
  }

  isMacOS(): boolean {
    return includesString(window.navigator.appVersion, 'Mac');
  }

  isUCBrowser(): boolean {
    return includesString(this._browser.name, 'UCBrowser');
  }

  isBaiduBrowser(): boolean {
    return includesString(this._browser.name, 'Baidu');
  }

  isBaiduHDBrowser(): boolean {
    return includesString(window.navigator.userAgent, 'BaiduHD');
  }

  // only for ios devices
  // android not supported https://en.wikipedia.org/wiki/Talk:360_Secure_Browser
  is360Browser(): boolean {
    return includesString(window.navigator.userAgent, 'QihooBrowser');
  }

  isUCOnAndroid(): boolean {
    return this.isUCBrowser() && this.isAndroid();
  }

  isUCBrowserChinese(): boolean {
    return this.isUCBrowser() && includesString(window.navigator.userAgent, 'aliapp');
  }

  isSamsungInternetBrowser(): boolean {
    return includesString(this._browser.name, 'Samsung Browser');
  }

  // detection for Edge browser with EdgeHTML engine
  isEdgeLegacy(): boolean {
    return includesString(this._uaParser.getResult().ua, 'Edge/');
  }

  // detection for Edge browser with Chromium engine
  isEdge(): boolean {
    return includesString(this._uaParser.getResult().ua, 'Edg/');
  }

  isIE(): boolean {
    return includesString(this._browser.name, 'IE');
  }

  isSafari(): boolean {
    return includesString(this._browser.name, 'Safari');
  }

  isSafariNextGen(): boolean {
    return this.isSafari() && Number(this._browser.version?.split('.')[0]) >= 15;
  }

  isMobileSafari(): boolean {
    return this.isSafari() && this.isMobile();
  }

  isChrome(): boolean {
    return includesString(this._browser.name, 'Chrome');
  }

  isFirefox(): boolean {
    return includesString(this._browser.name, 'Firefox');
  }

  isOpera(): boolean {
    return includesString(this._browser.name, 'Opera');
  }

  isChromeOnAndroid(): boolean {
    return this.isChrome() && this.isAndroid();
  }

  isQQBrowser(): boolean {
    return includesString(this._browser.name, 'QQBrowser');
  }

  isMiUiBrowser(): boolean {
    return includesString(this._browser.name, 'MIUI');
  }

  isIPhone(model?: IOSDeviceEnum): boolean {
    if (!model) {
      // deviceName stores specifically iphone model
      return includesString(this._device.model, IOSDeviceEnum.IPhone);
    }

    // eslint-disable-next-line eqeqeq
    return !!this.deviceName && this.deviceName.valueOf() == model.valueOf();
  }

  isIPad(): boolean {
    return includesString(this._device.model, 'ipad');
  }

  isPixel(): boolean {
    return includesString(this._device.model, 'pixel');
  }

  isIOSOperaMini(): Promise<boolean> {
    if (!this.isIOS()) {
      this._isOperaMini = false;
    }

    if (this._isOperaMini !== undefined) {
      return Promise.resolve(this._isOperaMini);
    }

    return requestAllResponseHeaders().then((result) => {
        this._isOperaMini = result.includes('opera');
        return this._isOperaMini;
      }
    );
  }

  getBrowser(): UAParser.IBrowser {
    const browserInfo = this._uaParser.getBrowser();

    return this.isEdgeLegacy()
      ? { ...browserInfo, name: 'edge-legacy' }
      : browserInfo;
  }

  getDevice(): UAParser.IDevice {
    if (this.desktopIOSBrowserRequested()) {
      return {
        model: 'iPad',
        type: 'tablet', // decided to use tablet type as such behavior is related to the tablets firstly
        vendor: 'Apple'
      };
    }

    return this._uaParser.getDevice();
  }

  // uaparser will recognize iOS13 as ipadOS in the future
  // https://github.com/faisalman/ua-parser-js/issues/387
  getOS(): UAParser.IOS {
    if (this.desktopIOSBrowserRequested()) {
      return {
        name: 'iOS',
        version: '13',
      };
    }

    return this._uaParser.getOS();
  }

  // Starting from 13 IOS Ipad's browser is detected as a desktop platform and native wrapper gives us not
  // insufficient userAgent(we haven't fully understanding where we are). So we are overriding this behavior.
  // - ontouchend is not available for desktop platform
  // - in windows we have no this cases
  // - we have desktop mode only in safari
  desktopIOSBrowserRequested(): boolean {
    return this._uaParser.getDevice().type === undefined
      && ('ontouchend' in document)
      && includesString(window.navigator.userAgent, 'macintosh');
  }

  getResult(): UAParser.IResult {
    return this._uaParser.getResult();
  }

  private getDeviceName(): SupportedDeviceName | undefined {
    const ua = navigator.userAgent;

    let deviceName: SupportedDeviceName | undefined;
    for (let [name, regexp] of DevicesRegExpMap) {
      if (regexp && regexp.test(ua)) {
        deviceName = name;
        break;
      }
    }

    if (deviceName === SupportedDeviceName.IPHONE) {
      deviceName = this.getIphoneModel();
    }

    return deviceName;
  }

  private getIphoneModel(): SupportedDeviceName | undefined {
    const devicePixelRatio = window.devicePixelRatio;
    const screenHeight = window.screen.height;
    const screenWidth = window.screen.width;
    const iphonePlus = (
        screenHeight === IphoneScreenHeight.IPHONE_6_7_8_PLUS
        || screenWidth === IphoneScreenHeight.IPHONE_6_7_8_PLUS)
      && (devicePixelRatio === 3);
    const iphoneUsual = (
        screenHeight === IphoneScreenHeight.IPHONE_6_7_8
        || screenWidth === IphoneScreenHeight.IPHONE_6_7_8)
      && (devicePixelRatio === 2);

    if (iphoneUsual || iphonePlus) {
      switch (this.getPhoneGPUName()) {
        case ListNameGPU.APPLE_A8_GPU:
          return iphonePlus ? SupportedDeviceName.IPHONE_6_PLUS : SupportedDeviceName.IPHONE_6;
        case ListNameGPU.APPLE_A9_GPU:
          return iphonePlus ? SupportedDeviceName.IPHONE_6S_PLUS : SupportedDeviceName.IPHONE_6S;
        case ListNameGPU.APPLE_A10_GPU:
          return iphonePlus ? SupportedDeviceName.IPHONE_7_PLUS : SupportedDeviceName.IPHONE_7;
        case ListNameGPU.APPLE_A11_GPU:
          return iphonePlus ? SupportedDeviceName.IPHONE_8_PLUS : SupportedDeviceName.IPHONE_8;
        default:
          return iphonePlus ? SupportedDeviceName.IPHONE_6_7_8_PLUS : SupportedDeviceName.IPHONE_6_7_8;
      }
    }

    if (screenHeight === IphoneScreenHeight.IPhone_5_SE || screenWidth === IphoneScreenHeight.IPhone_5_SE) {
      return SupportedDeviceName.IPHONE_5_SE;
    }

    // this is iphone x and iphone xs
    if (screenHeight === IphoneScreenHeight.IPHONE_X_XS || screenWidth === IphoneScreenHeight.IPHONE_X_XS) {
      return SupportedDeviceName.IPHONE_X;
    }

    const isIPhoneXRHeight =
      (screenHeight === IphoneScreenHeight.IPHONE_XR_ZOOMED_MODE
        || screenWidth === IphoneScreenHeight.IPHONE_XR_ZOOMED_MODE)
      || (screenHeight === IphoneScreenHeight.IPHONE_XMAX_XR
        || screenWidth === IphoneScreenHeight.IPHONE_XMAX_XR);

    if (isIPhoneXRHeight && devicePixelRatio === 2) {
      return SupportedDeviceName.IPHONE_X_R;
    }

    if ((screenHeight === IphoneScreenHeight.IPHONE_XMAX_XR || screenWidth === IphoneScreenHeight.IPHONE_XMAX_XR)
      && devicePixelRatio === 2) {
      return SupportedDeviceName.IPHONE_X_R;
    }

    if ((screenHeight === IphoneScreenHeight.IPHONE_XMAX_XR || screenWidth === IphoneScreenHeight.IPHONE_XMAX_XR)
      && devicePixelRatio === 3) {
      return SupportedDeviceName.IPHONE_X_MAX;
    }

    return;
  }

  // create WEBGL canvas and take GPU info for detecting devices.
  // for more info read https://51degrees.com/blog/device-detection-for-apple-iphone-and-ipad
  private getPhoneGPUName(): string | undefined {
    const canvas = document.createElement('canvas');
    if (canvas?.getContext) {
      const context = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      if (context && 'getExtension' in context) {
        const info = context.getExtension('WEBGL_debug_renderer_info');
        if (info) {
          return context.getParameter(info.UNMASKED_RENDERER_WEBGL);
        }
      }
    }

    return;
  }
}

function requestAllResponseHeaders(): Promise<string> {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.onreadystatechange = () => {
      if (request.readyState === XMLHttpRequest.DONE) {
        resolve(request.getAllResponseHeaders());
      }
    };
    request.onerror = reject;
    // @ts-ignore
    request.open('HEAD', document.location);
    request.send();
  });
}

export type UAParserResult = UAParser.IResult;

/**
 * Create single ua-parser instance for app
 */
export const uaParser = new UaParser();

export * from './types';
/* eslint-enable */
