import React, { useState } from 'react';
import classNames from 'classnames';

import { withTranslation } from 'react-i18next';
import { uaParser } from '../../../common/ua-parser/ua-parser';

import styles from './menu.module.scss';
import LangSwitcher from '../../../common/lang-switcher/lang-switcher';

enum MenuItems {
  WhoWeAre = 'Who we are',
  HowWeWork = 'How we work',
  AboutUs = 'About us',
  Technologies = 'Technologies',
  Projects = 'Projects',
  Partners = 'Partners',
  Contacts = 'Contacts',
}
/* eslint-disable */
// @ts-ignore
function Menu({ t }) {
  const [showMenu, setShowMenu] = useState(false);
  const bodyElement = document.getElementsByTagName('body')[0];
  const htmlElement = document.getElementsByTagName('html')[0];
  const headerHeight = 100;

  if (uaParser.isMobile()) {
    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
  }

  const menuClosedHandler = () => {
    const scrollY = bodyElement.style.top;
    bodyElement.style.position = '';
    bodyElement.style.top = '';
    htmlElement.style.scrollBehavior = 'unset';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    htmlElement.style.scrollBehavior = 'smooth';
    setShowMenu(false);
  }

  const showMenuHandler = () => {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    bodyElement.style.position = 'fixed';
    bodyElement.style.top = `-${scrollY}`;

    setShowMenu(true);
  }

  const buttonToggleAction = () => {
    if (!showMenu) {
      showMenuHandler();
    } else {
      menuClosedHandler();
    }
  }

  const menuClassNames = classNames(
    styles.menu,
    {
      [styles.menu_mobile]: uaParser.isMobile(),
      [styles.menu_active]: showMenu,
    }
  );

  const smoothScrollTo = (el: string) => {
    menuClosedHandler();
    const targetElRef = document.getElementById(el);
    const scrollConfigs = {
      top: targetElRef ? targetElRef?.offsetTop - headerHeight : 0,
      behavior: 'smooth'
    }
    window.scrollTo(scrollConfigs as ScrollToOptions);
  }

  return (
    <div className={menuClassNames}>
      {uaParser.isMobile() && (
        <div className={styles.menu__button} onClick={buttonToggleAction}>
          <svg className={styles.menu__button_icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path className={styles.icon_burger_top} d="M4,6A1,1,0,0,1,5,5H19a1,1,0,0,1,0,2H5A1,1,0,0,1,4,6Z" />
            <path className={styles.icon_burger_middle} d="M4,12a1,1,0,0,1,1-1H19a1,1,0,0,1,0,2H5A1,1,0,0,1,4,12Z" />
            <path className={styles.icon_burger_bottom} d="M5,17a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z" />
          </svg>
        </div>
      )}
      <div className={styles.menu__list}>
        {(Object.keys(MenuItems) as Array<keyof typeof MenuItems>).map(
          (menuItem, index) => {
            return <a onClick={() => smoothScrollTo(menuItem)} className={styles.menu__item} key={index}>{t(menuItem)}</a>;
          },
        )}

        {uaParser.isMobile() && <div className={styles.menu__item} style={{  }}><LangSwitcher /></div>}

        {!uaParser.isMobile() && <LangSwitcher />}

      </div>
    </div>
  );
}

export default withTranslation()(Menu)
