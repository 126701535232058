export type IfOptional<T, Then = T> = T extends undefined | null ? Then : never;

/**
 * Type guard (also great for `filter` operations), that doesn't like `undefined` or `null` values
 */
export function isSomething<T>(x: T | undefined | null): x is NonNullable<T> {
  // Quick comparison with both undefined and null
  // eslint-disable-next-line eqeqeq
  return x != null;
}

export function assertExists<T>(
  x: T,
  errorMessage: string,
  ctx?: object,
): asserts x is NonNullable<T> {
  // Quick comparison with both undefined and null
  // eslint-disable-next-line eqeqeq
  if (x == null) {
    throw new Error(`assertExists: ${errorMessage}`, ctx);
  }
}

// unwrap accepts only value of optional type
export function unwrap<T>(
  value: IfOptional<T>,
  assertion: string,
  ctx?: object,
): NonNullable<T> {
  assertExists(value, assertion, ctx);
  return value;
}

// uaParser uses whitespaces as separator that doesn't fit for classnames
export function toSnakeCase(s?: string) {
  return s?.replace(/ /gi, '-');
}
