import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import Typed from 'react-typed';
import styles from './greeting.module.scss';

import uaFlag from './images/icon-flag-ua.svg';
import contentImage from './images/greeting-content.jpg';

/* eslint-disable */
// @ts-ignore
const Greeting = ({ t }) => {
  const [greetingTyped, setGreetingTyped] = useState('');
  const [greeting, setGreeting] = useState('');
  let isUa: boolean;

  i18n.changeLanguage().then((data) => {
    isUa = (i18n.language === 'ua')
  })

  useEffect(() => {
    const today = new Date();
    const curHr = today.getHours();

    if (curHr < 12) {
      setGreetingTyped('morning');
      setGreeting('Good');
    } else if (curHr < 18) {
      setGreetingTyped('afternoon');
      setGreeting('Good2');
    } else {
      setGreetingTyped('evening');
      setGreeting('Good2');
    }
  }, [])

  function getGreetingLocale(): string {
    return (i18n.language === 'ua') ? t(greeting) : 'Good'
  }

  return (
    <div className={styles.greeting} id="HowWeWork">
      <div className={`${styles.greetingTitle} h1 typed-title`}>
        { getGreetingLocale() }&nbsp;{ greetingTyped ? getTyped(t(greetingTyped)) : getTyped() }
      </div>
      <div className={`${styles.greetingTitle} h1`}>
        {t('We are from Ukraine')}
        <img className={styles.greetingFlag}
             src={uaFlag}
             alt={'Ukraine Flag'}/>
      </div>

      <div className={styles.greetingContent}>
        <div className={styles.greetingContentText}>
          {t('We are more than')} <span className={styles.colorBlue}>{t('500 specialists')}</span>, {t('in 2 years we have grown 4 times, strengthened our expertise in various areas (Product management, Business and Systems analysis, Systems administration and DevOps practices, Infrastructure), introduced flexible work methodologies and show high efficiency in the implementation of projects and products.')}
        </div>
        <div className={styles.greetingContent_image_wrapper}>
          <img className={styles.greetingContent_image} src={contentImage} alt="Greeting" />
        </div>
      </div>
    </div>
  );
};

function getTyped(arg?: string) {
  if (arg) {
    return <Typed strings={[arg]} typeSpeed={150} backSpeed={100} loop />;
  }
}

export default withTranslation()(Greeting);
