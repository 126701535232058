import React, { useState } from 'react';
import i18next from 'i18next';

import styles from './lang-switcher.module.scss';
import { uaParser } from '../ua-parser/ua-parser';

// @ts-ignore
export default function LangSwitcher() {
  const [selected, setSelected] = useState(i18next.language);
  const switchLangTo = (locale: string) => {
    i18next.changeLanguage(locale);
    setSelected(locale);
  };
  return (
    <div className={styles.lsContainer} style={{ marginLeft: (uaParser.isMobile() ? 0 : '48px') }}>

      <div
        className={`${styles.lsSwitcher} ${selected === 'en' ? styles.selected : ''}`}
        onClick={() => switchLangTo('en')}
      >
        EN
      </div>

      <div className={styles.lsSeparator} />

      <div
        className={`${styles.lsSwitcher} ${selected === 'ua' ? styles.selected : ''}`}
        onClick={() => switchLangTo('ua')}
      >
        UA
      </div>

    </div>
  );
}
