import React from 'react';
import { withTranslation } from 'react-i18next';

import styles from './about-us.module.scss';

/* eslint-disable */
// @ts-ignore
function AboutUs({ t }) {
  return (
    <div className={styles.about} id="AboutUs">
      <div className={styles.aboutTitle}>{t('About us')}</div>

      <div className={styles.aboutContent}>

        <div className={styles.aboutContentItem}>
          <div className={styles.aboutContentItemTitle}>
            {t('Full software')}
            <br />
            {t('development life cycle')}
          </div>
          <div className={styles.aboutContentItemDescription}>
            {t('We will develop your product from idea to implementation and support. Follows the best coding and implementation practices and uses the most effective technologies and resources.')}
          </div>
        </div>

        <div className={styles.aboutContentItem}>
          <div className={styles.aboutContentItemTitle}>{t('Extensive experience in implementing solutions')}</div>
          <div className={styles.aboutContentItemDescription}>
            {t(`Development from scratch of large enterprise solutions - business landing, customer service in branches, web platforms, design system, mobile banking for First Ukrainian International Bank. Implementation of complex integration solutions with major market vendors - Creatio, Corezoid, IBM, Microsoft, AWS, Cisco and others.`)}
          </div>
        </div>

        <div className={styles.aboutContentItem}>
          <div className={styles.aboutContentItemTitle}>
            {t('Flexible')}
            <br />
            {t('product delivery')}
          </div>
          <div className={styles.aboutContentItemDescription}>
            {t('Our specialists are located in different parts of the world, we have been effectively working remotely for more than 3 years using slack/teams/jira and more. The outcome and the value that the customers receive are crucial to us.')}
          </div>
        </div>

      </div>
    </div>
  );
}

export default withTranslation()(AboutUs)
