import React from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import styles from './artifacts.module.scss';

import iconTriangleRef from './images/icon-triangle.png';
import iconHeartRef from './images/icon-heart.svg';

/* eslint-disable */
// @ts-ignore
function Artifacts({ t }) {
  const isUa = () => i18next.language === 'ua';
  return (
    <div className={styles.artifacts}>

      <div className={styles.artifactsTitle}>
        { isUa() ? t('Artifacts') : t('Development') }
        <br />
        { isUa() ? t('development') : t('artifacts') }
      </div>
      <div className={styles.artifactsContent}>
        <div className={styles.artifactsContentRow}>
          <div
            className={classNames(
              styles.artifactsContentItem,
              styles.artifactsContentItem_project_documentation,
            )}
          >
            <div className={styles.artifactsContentItemIcon}>
              <img src={iconTriangleRef} alt="Icon Triangle" />
            </div>
            <div className={styles.artifactsContentItemDescription}>
              {t('Project documentation')}
            </div>
          </div>
          <div
            className={classNames(
              styles.artifactsContentItem,
              styles.artifactsContentItem_codebase,
            )}
          >
            <div className={styles.artifactsContentItemIcon}>
              <img src={iconTriangleRef} alt="Icon Triangle" />
            </div>
            <div className={styles.artifactsContentItemDescription}>
              {t('Codebase')}
            </div>
          </div>
          <div
            className={classNames(
              styles.artifactsContentItem,
              styles.artifactsContentItem_project_deployment_scripts,
            )}
          >
            <div className={styles.artifactsContentItemIcon}>
              <img src={iconTriangleRef} alt="Icon Triangle" />
            </div>
            <div className={styles.artifactsContentItemDescription}>
              {t('Product Deployment Scripts')}
            </div>
          </div>
        </div>
        <div className={styles.artifactsContentRow}>
          <div
            className={classNames(
              styles.artifactsContentItem,
              styles.artifactsContentItem_operational_documentation,
            )}
          >
            <div className={styles.artifactsContentItemIcon}>
              <img src={iconTriangleRef} alt="Icon Triangle" />
            </div>
            <div className={styles.artifactsContentItemDescription}>
              {t('Operational documentation')}
            </div>
          </div>
          <div
            className={classNames(
              styles.artifactsContentItem,
              styles.artifactsContentItem_load_testing_results,
            )}
          >
            <div className={styles.artifactsContentItemIcon}>
              <img src={iconTriangleRef} alt="Icon Triangle" />
            </div>
            <div className={styles.artifactsContentItemDescription}>
              {t('Load testing results')}
            </div>
          </div>
          <div
            className={classNames(
              styles.artifactsContentItem,
              styles.artifactsContentItem_any_other,
            )}
          >
            <div className={styles.artifactsContentItemIcon}>
              <img src={iconHeartRef} alt="Icon Heart" />
            </div>
            <div className={styles.artifactsContentItemDescription}>
              {t('And other')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Artifacts)
