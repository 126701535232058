import React, { useEffect, useState } from 'react';

import { uaParser } from '../common/ua-parser/ua-parser';
import { DocumentHTML } from '../common/browser-state/document-html.component';
import Header from '../sections/header/header.component';
import Greeting from '../sections/greeting/greeting';
import Subheader from '../sections/subheader/subheader';
import Benefits from '../sections/benefits/benefits';
import Artifacts from '../sections/artifacts/artifacts';
import AboutUs from '../sections/about-us/about-us';
import HowWeWork from '../sections/how-we-work/how-we-work';
import OurProjects from '../sections/our-projects/our-projects';
import Footer from '../sections/footer/footer';
import ExpertiseAndTechnology from '../sections/expertise-and-technology/expertise-and-technology';
import ExpertiseTabs from '../sections/expertise-tabs/expertise-tabs';
import ExpertiseAccordion from '../sections/expertise-accordion/expertise-accordion';

import styles from './app.module.scss';
import OurPartners from '../sections/our-partners/our-partners';

export default function App() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  return (
    <div className={styles.orbitek__app}>
      <DocumentHTML />

      <Header />

      <Subheader />

      <Greeting />

      <Benefits />

      <AboutUs />

      <HowWeWork />

      <ExpertiseAndTechnology />

      { uaParser.isMobile() ? <ExpertiseAccordion /> : <ExpertiseTabs /> }

      <Artifacts />

      <OurPartners isMobile={isMobile} />

      <OurProjects isMobile={isMobile} />

      <Footer />

    </div>
  );
}
