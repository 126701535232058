import React from 'react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import styles from './our-projects.module.scss';

// desktop refs
import wideRef from './images/section-wide.png';
import wideImsRef from './images/ims.png';
import designRef from './images/section-design.png';
import rbsRef from './images/section-rbs.png';
import mobileBankingRef from './images/section-mobile-banking.png';
import mobileAppRef from './images/section-mobile-app.png';

// mobile refs
import mobWideRef from './images/mob-section-wide.png';
import mobMobileBankingRef from './images/mob-section-mobile-banking.png';
import mobRbsRef from './images/mob-section-rbs.png';
import mobMobileAppRef from './images/mob-section-mobile-app.png';
import mobDesignRef from './images/mob-section-design.png';
import { uaParser } from '../../common/ua-parser/ua-parser';

// @eslint-disable
function OurProjects(props: { isMobile: boolean, t: any }) {
  const section3 = (
    <div className={styles.opCard}>
      <div className={styles.opCardTitle} style={{ color: '#FFFFFF' }}>
        <>
          {t('Bank')}
          <br />
          {t('mobile app')}
        </>
      </div>
      <div className={styles.opCardCount} style={{ color: '#FFFFFF' }}>03</div>
      <div className={styles.opCardBg}>
        <img src={props.isMobile ? mobMobileAppRef : mobileAppRef} alt="Mobile Application" />
      </div>
    </div>
  );
  const imsMobileStyles = {
    height: '570px',
    display: 'flex',
    background: '#F5F5FA',
  };

  // @ts-ignore
  const section4 = (
    <div className={styles.opCard}>
      <div className={styles.opCardWrapper}>
        <div className={styles.opCardTitle}>
          <>
            {t('Retail Business')}
            <br />
            {t('Solution')}
          </>
        </div>
        <div className={styles.opCardCount}>04</div>
      </div>
      <div className={styles.opCardDescription}>
        <>
          {t('Web application for servicing bank customers in branches. Complete management of all banking services')}
        </>
      </div>
      <div className={styles.opCardBg}>
        <img src={props.isMobile ? mobRbsRef : rbsRef} alt="Retail Business Solution" />
      </div>
    </div>
  );

  return (
    <div className={styles.op} id="Projects">
      <div className={styles.opTitle}>
        {props.t('Our projects')}
      </div>

      <div className={styles.opCardWide}>
        <div className={styles.opCardTitle}>
          {props.t('Online Banking for')}
          <br />
          {props.t('corporate clients')}
        </div>
        <div className={styles.opCardCount}>01</div>
        <div className={styles.opCardBg}>
          <img src={props.isMobile ? mobWideRef : wideRef} alt="Online Banking for Corporate Clients" />
        </div>
      </div>

      <div className={styles.opCardCol}>
        <div className={styles.opCardWrapper}>

          {/* 2 */}
          <div className={styles.opCard}>
            <div className={styles.opCardTitle} style={{ color: '#FFFFFF' }}>
              {props.t('Mobile banking for')}
              <br />
              {props.t('First Ukrainian')}
              <br />
              {props.t('International Bank')}
            </div>
            <div className={styles.opCardCount} style={{ color: '#FFFFFF' }}>02</div>
            <div className={styles.opCardBg}>
              <img src={props.isMobile ? mobMobileBankingRef : mobileBankingRef} alt="FUIB Mobile Banking" />
            </div>
          </div>

          {
            uaParser.isMobile()
              ? section3
              : section4
          }

        </div>

        <div className={styles.opCardWrapper}>

          {
            uaParser.isMobile()
              ? section4
              : section3
          }

          <div className={styles.opCard}>
            <div className={styles.opCardTitle}>
              {props.t('Design system for')}
              <br />
              {props.t('banking products')}
            </div>
            <div className={styles.opCardCount}>05</div>
            <div className={styles.opCardBg}>
              <img src={props.isMobile ? mobDesignRef : designRef} alt="FUIB Design System" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.opCardWide}>
        <div className={styles.opCardTitle}>
          {props.t('Identify')}
          <br />
          {props.t('Management System')}
        </div>
        <div className={styles.opCardDescription}>
          <>
            {t('Web application for Role Access, ordering access')}
            <br />
            {t('rights and tracking the progress of applications')}
          </>
        </div>
        <div className={styles.opCardCount}>06</div>
        <div className={styles.opCardBg} style={props.isMobile ? imsMobileStyles : { background: '#F5F5FA' }}>
          <img src={wideImsRef} style={{ marginLeft: props.isMobile ? '0px' : 'auto' }} alt="Identify Management System" />
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(OurProjects);
