import React from 'react';
import { withTranslation } from 'react-i18next';

import styles from './how-we-work.module.scss';

import methodologyRef from './images/icon-methodology.png';
import contextRef from './images/icon-context.png';
import assemblingRef from './images/icon-assembling.png';
import planningRef from './images/icon-planning.png';
import { uaParser } from '../../common/ua-parser/ua-parser';

/* eslint-disable */
// @ts-ignore
function HowWeWork({ t }) {
  const hwwTitle = uaParser.isMobile() ?  t('How we work') : `${t('How')} <br /> ${t('we work')}`;

  return (
    <div className={styles.hww} id="HowWeWork">

      <div className={styles.hwwContentItem}>
        <div className={styles.hwwTitleWrapper}>
          <div
            className={styles.hwwTitle}
            dangerouslySetInnerHTML={{ __html: hwwTitle }}
          />
        </div>
      </div>

      <div className={styles.hwwContentItem}>

        <div className={styles.hwwContentItemInner}>
          <img src={assemblingRef} alt='Icon Assembling' />
          <div className={styles.hwwContentItemTitle}>
            {t('Аssembling a')}
            <br />
            {t('product team')}
          </div>
          <div className={styles.hwwContentItemDescription}>
            {t('For unique project / product goals. You may form a team. The team will only include professionals that are required to develop a product to bring the most value to it.')}
          </div>
        </div>

        <div className={styles.hwwContentItemInner}>
          <img src={contextRef} alt='Icon Context' />
          <div className={styles.hwwContentItemTitle}>
            {t('Dive into task')}
            <br />
            {t('context')}
          </div>
          <div className={styles.hwwContentItemDescription}>
            {t('It is important for us to understand what kind of goal we want to achieve by performing the specified task in order to bring the maximum value, to offer alternatives.')}
          </div>
        </div>

      </div>

      <div className={styles.hwwContentItem}>

        <div className={styles.hwwContentItemInner}>
          <img src={planningRef} alt='Icon Planning' />
          <div className={styles.hwwContentItemTitle}>
            {t('Planning together')}
          </div>
          <div className={styles.hwwContentItemDescription}>
            {t('Prior to each sprint, we cooperatively plan the tasks, as well as hold team meetings. We use a transparent and efficient communication method.')}
          </div>
        </div>

        <div className={styles.hwwContentItemInner}>
          <img src={methodologyRef} alt='Icon Methodology' />
          <div className={styles.hwwContentItemTitle}>
            {t('Agile/Scrum')}
            <br />
            {t('methodology')}
          </div>
          <div className={styles.hwwContentItemDescription}>
            {t('We break the project into one or two week sprints, which optimizes project manageability by separating huge tasks into little ones, enabling us to deliver the product faster and more frequently, gives flexibility for adjustments.')}
          </div>

        </div>
      </div>

    </div>
  );
}

export default withTranslation()(HowWeWork)
