import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import Logo from './logo/logo.component';
import Menu from './menu/menu.component';

import styles from './header.module.scss';

export default function Header() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const headerClassNames = classNames(styles.header, {
    [styles.header__active]: offset > 0,
  });

  return (
    <header className={headerClassNames}>
      <div className={classNames(styles.header__container, 'container')}>
        <div className={styles.header__logo}>
          <Logo />
        </div>
        <div className={styles.header__menu}>
          <Menu />
        </div>
      </div>
    </header>
  );
}
